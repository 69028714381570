.paddingall{
    padding: 10px;
}
.MuiDrawer-root .MuiPaper-root{
    border: none;
  }

.pricetitle{
    font-weight: 600;
}

.pricesubtitle{
    font-size: 14px;
}

.iconstyle{
    margin-top: 20px;
    margin-left: 20px;
    color: aliceblue;
}

.iconstyleblack{
    margin-top: 20px;
    margin-left: 20px;
    color: #000000;
}

.gradient {
    background: rgb(40, 34, 70);
    background: linear-gradient(158deg, rgba(40, 34, 70, 1) 0%, rgba(30, 47, 141, 1) 100%);
}

.gradientLight {
    background: rgb(53, 138, 148, 1);
    background: linear-gradient(158deg, rgba(53, 138, 148, 1) 0%, rgba(91, 180, 96, 1) 100%);
}

.bodyBgColor{
    /* min-height: 100vh; */
    min-height: 90.4vh;

    background: #ECEFF1;
    background: linear-gradient(158deg, rgb(224, 224, 224) 0%, rgba(233, 237, 254) 100%);
}
.bg-orange-50 {
    --tw-bg-opacity: 1!important;
    background-color: rgb(255 247 237/var(--tw-bg-opacity))!important;
}